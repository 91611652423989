import { getGRP } from "~lib/getGRP";
import {
  AB_TESTS,
  AMPLIFY_RBK,
  FORCE_AB_TESTS
} from "~lib/keyNamesOfLocalStorage";
import { hasLocalStorage } from "~lib/localStorage";
import { logUserInteraction } from "~lib/log-page-views";

/***
 * After changes go live we should work on deleting AB tests
 */

// Banners should go live 30 days before SIWI (June 15)
export const ABTEST_RB_RESCOM_HOMEBANNER = "rb_rescom_homebanner";
export const ABTEST_RB_RESCOM_HOMEBANNER1 = "rb_rescom_homebanner1"; // Banner is on the homepage (0%)

export const ABTEST_RB_RESCOM_MAINTENANCE = "rb_rescom_maintenance";
export const ABTEST_RB_RESCOM_MAINTENANCE0 = "rb_rescom_maintenance0"; // Show normal page
export const ABTEST_RB_RESCOM_MAINTENANCE1 = "rb_rescom_maintenance1"; // Redirect to maintenance page

export const ABTEST_RB_CRB_EXPERIENCE_AI_WRITER = "rb_crb_experience_ai_writer";
export const ABTEST_RB_CRB_EXPERIENCE_AI_WRITER0 =
  "rb_crb_experience_ai_writer0"; // No display experience ai_writer
export const ABTEST_RB_CRB_EXPERIENCE_AI_WRITER1 =
  "rb_crb_experience_ai_writer1"; // Display experience ai_writer

export const ABTEST_RB_CRB_EXPERIENCE_AI_WRITER_TOUR_GUIDE =
  "rb_crb_experience_ai_writer_tour_guide";

export const ABTEST_RB_CRB_EXPERIENCE_AI_WRITER_TOUR_GUIDE0 =
  "rb_crb_experience_ai_writer_tour_guide0"; // No guide

export const ABTEST_RB_CRB_EXPERIENCE_AI_WRITER_TOUR_GUIDE1 =
  "rb_crb_experience_ai_writer_tour_guide1"; // Tour guide

export const ABTEST_RB_BUILDER_UPLOAD_FEED_BACK = "rb_builder_uploadfeedback";
export const ABTEST_RB_BUILDER_UPLOAD_FEED_BACK0 = "rb_builder_uploadfeedback0"; // No display experience uploadfeedback modal
export const ABTEST_RB_BUILDER_UPLOAD_FEED_BACK1 = "rb_builder_uploadfeedback1"; // Display experience uploadfeedback modal

export const ABTEST_RB_CRB_REBRANDING_FONT = "rb_crb_rebranding_font";
export const ABTEST_RB_CRB_REBRANDING_INDEED_FONT0 =
  "rb_crb_rebranding_Indeed_font0"; // Keep Resume.com font
export const ABTEST_RB_CRB_REBRANDING_INDEED_FONT1 =
  "rb_crb_rebranding_Indeed_font1"; // Use Indeed Sans + Noto Sans

export const ABTEST_RB_RESCOM_REBRAND_TO_INDEED = "rb_rescom_rebrand_to_Indeed";
export const ABTEST_RB_RESCOM_REBRAND_TO_INDEED0 = "rb_rescom_no_rebrand0"; // Still using the RES style
export const ABTEST_RB_RESCOM_REBRAND_TO_INDEED1 =
  "rb_rescom_rebrand_to_Indeed1"; // Using the Indeed style

export const ABTEST_RB_RESUME_LIMITATION_MODAL = "rb_resume_limitation_modal";
export const ABTEST_RB_RESUME_LIMITATION_MODAL0 = "rb_resume_limitation_modal0"; // No modal
export const ABTEST_RB_RESUME_LIMITATION_MODAL1 = "rb_resume_limitation_modal1"; // Display limitation modal for 6th resume

export const ABTEST_RB_RB_RESCOM_PROMOTION_CARD = "rb_rescom_promotion_card";
export const ABTEST_RB_RB_RESCOM_PROMOTION_CARD0 = "rb_rescom_promotion_card0"; // No Interview Copilot promotion card
export const ABTEST_RB_RB_RESCOM_PROMOTION_CARD1 = "rb_rescom_promotion_card1"; // With Interview Copilot promotion card

/*
Example:
test_name = "rb_rescom_crb"
group_1_id = "rb_rescom_crb0"
group_2_id = "rb_rescom_crb1"

===IN USER'S LOCAL STORAGE===
"ab_tests": {
  "rb_rescom_crb": {
    "group": "rb_rescom_crb0",
    "lastChecked": 1608149962948
  }
}

"force_ab_tests": {
  "rb_rescom_crb": "rb_rescom_crb1"
}

to test for group membership, an example call would be:
 isInGroup(ABTEST_RB_RESCOM_CRB, ABTEST_RB_RESCOM_CRB1);

*/

export const isInGroup = async (
  testID: string,
  groupID: string
): Promise<boolean> => {
  let group = null;

  if (hasLocalStorage()) {
    // Guests need to be able to be AB tested, and this is before they are created in the database.
    const __rbk = localStorage.getItem(AMPLIFY_RBK) || null;
    const userID = __rbk && (JSON.parse(__rbk)["data"] || null);

    // First we check to see if a superuser is forcing themselves into a test group.
    const forceABTests = localStorage.getItem(FORCE_AB_TESTS) || null;
    if (forceABTests !== null) {
      group = JSON.parse(forceABTests)[testID] || null;
    }

    /*
      Next we check for a cached group. If the group has not been pulled in the last 30 minutes, we grab a new one.
      This is because:
        - The test groups may have been rebalanced
        - The test may have been turned off, and the user is put back into the default group
        - We don't want to send lambda/GraphQL requests too often
    */
    if (group == null) {
      const abTests = localStorage.getItem(AB_TESTS) || null;
      const parsedABTests = abTests && (JSON.parse(abTests) || null);
      const test = parsedABTests && (parsedABTests[testID] || null);
      const lastChecked = parsedABTests && (parsedABTests.lastChecked || 0);

      const currentTime = new Date().getTime();
      if (test === null || (currentTime - lastChecked) / 60000 > 30) {
        const response = await getGRP(testID, userID);
        group = (await response?.data?.group) || testID + "0";
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        localStorage.setItem([testID], group);
        logUserInteraction({
          category: "ab_test",
          action: "log_ab_test_group",
          label: testID,
          arbitrary1: group
        });
        const newGroup = {
          ...searchABtestGroup(),
          lastChecked: currentTime
        };
        localStorage.setItem(AB_TESTS, JSON.stringify(newGroup));
      } else {
        group = test;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        localStorage.removeItem([testID]);
      }
    }
  }
  return group === groupID;
};

export const isInGroupRbRescomHomeBanner = () =>
  isInGroup(ABTEST_RB_RESCOM_HOMEBANNER, ABTEST_RB_RESCOM_HOMEBANNER1);

export const isInGroupRbRescomMaintenance = () =>
  isInGroup(ABTEST_RB_RESCOM_MAINTENANCE, ABTEST_RB_RESCOM_MAINTENANCE1);

export const isInGroupRbCrbExperienceAIWriter0 = () =>
  isInGroup(
    ABTEST_RB_CRB_EXPERIENCE_AI_WRITER,
    ABTEST_RB_CRB_EXPERIENCE_AI_WRITER0
  );

export const isInGroupRbCrbExperienceAIWriter1 = () =>
  isInGroup(
    ABTEST_RB_CRB_EXPERIENCE_AI_WRITER,
    ABTEST_RB_CRB_EXPERIENCE_AI_WRITER1
  );

export const isInGroupRbCrbExperienceAIWriterTourGuide0 = () =>
  isInGroup(
    ABTEST_RB_CRB_EXPERIENCE_AI_WRITER_TOUR_GUIDE,
    ABTEST_RB_CRB_EXPERIENCE_AI_WRITER_TOUR_GUIDE0
  );

export const isInGroupRbCrbExperienceAIWriterTourGuide1 = () =>
  isInGroup(
    ABTEST_RB_CRB_EXPERIENCE_AI_WRITER_TOUR_GUIDE,
    ABTEST_RB_CRB_EXPERIENCE_AI_WRITER_TOUR_GUIDE1
  );

export const isInGroupRbBuilderUploadFeedBack0 = () =>
  isInGroup(
    ABTEST_RB_BUILDER_UPLOAD_FEED_BACK,
    ABTEST_RB_BUILDER_UPLOAD_FEED_BACK0
  );

export const isInGroupRbBuilderUploadFeedBack1 = () =>
  isInGroup(
    ABTEST_RB_BUILDER_UPLOAD_FEED_BACK,
    ABTEST_RB_BUILDER_UPLOAD_FEED_BACK1
  );

export const isInGroupRbCrbRebrandingIndeedFont0 = () =>
  isInGroup(
    ABTEST_RB_CRB_REBRANDING_FONT,
    ABTEST_RB_CRB_REBRANDING_INDEED_FONT0
  );

export const isInGroupRbCrbRebrandingIndeedFont1 = () =>
  isInGroup(
    ABTEST_RB_CRB_REBRANDING_FONT,
    ABTEST_RB_CRB_REBRANDING_INDEED_FONT1
  );

export const isInGroupRbRescomRebrandToIndeed0 = () =>
  isInGroup(
    ABTEST_RB_RESCOM_REBRAND_TO_INDEED,
    ABTEST_RB_RESCOM_REBRAND_TO_INDEED0
  );

export const isInGroupRbRescomRebrandToIndeed1 = () =>
  isInGroup(
    ABTEST_RB_RESCOM_REBRAND_TO_INDEED,
    ABTEST_RB_RESCOM_REBRAND_TO_INDEED1
  );

export const isInGroupRbResumeLimitationModal0 = () =>
  isInGroup(
    ABTEST_RB_RESUME_LIMITATION_MODAL,
    ABTEST_RB_RESUME_LIMITATION_MODAL0
  );

export const isInGroupRbResumeLimitationModal1 = () =>
  isInGroup(
    ABTEST_RB_RESUME_LIMITATION_MODAL,
    ABTEST_RB_RESUME_LIMITATION_MODAL1
  );

export const isInGroupRbRescomPromotionCard0 = () =>
  isInGroup(
    ABTEST_RB_RB_RESCOM_PROMOTION_CARD,
    ABTEST_RB_RB_RESCOM_PROMOTION_CARD0
  );

export const isInGroupRbRescomPromotionCard1 = () =>
  isInGroup(
    ABTEST_RB_RB_RESCOM_PROMOTION_CARD,
    ABTEST_RB_RB_RESCOM_PROMOTION_CARD1
  );

const searchABtestGroup = () => {
  const abTestsGroupsArr = [
    ABTEST_RB_RESCOM_HOMEBANNER,
    ABTEST_RB_RESCOM_MAINTENANCE,
    ABTEST_RB_CRB_EXPERIENCE_AI_WRITER,
    ABTEST_RB_CRB_EXPERIENCE_AI_WRITER_TOUR_GUIDE,
    ABTEST_RB_BUILDER_UPLOAD_FEED_BACK,
    ABTEST_RB_CRB_REBRANDING_FONT,
    ABTEST_RB_RESCOM_REBRAND_TO_INDEED,
    ABTEST_RB_RESUME_LIMITATION_MODAL,
    ABTEST_RB_RB_RESCOM_PROMOTION_CARD
  ];
  const allTestGroups = {};
  for (const index in abTestsGroupsArr) {
    const abTest = localStorage.getItem(abTestsGroupsArr[index]);
    if (abTest) {
      allTestGroups[abTestsGroupsArr[index]] = localStorage.getItem(
        abTestsGroupsArr[index]
      );
    }
  }
  return allTestGroups;
};
